export function Grid(
	{
		columns = 16,
		rows = 16,
		scalingFactor = 10,
		strokeWidth = 0.25,
		...props
	}) {

	return (
		<svg viewBox={`0 0 ${columns * scalingFactor} ${rows * scalingFactor}`}
		     opacity={0.8}
		     {...props}
		>
			<g strokeWidth={strokeWidth}
			   stroke="#fb7185ff"
			   fill="none"
			>
				{
					[
						...Array(rows)
							.keys(),
					]
						.map((row) => {
							return [
								...Array(columns)
									.keys(),
							]
								.map((column) => {
									return (
										<rect key={`${row}-${column}`}
										      width={scalingFactor}
										      height={scalingFactor}
										      x={column * scalingFactor}
										      y={row * scalingFactor}
										></rect>
									);
								});
						})
				}

			</g>
		</svg>
	);
}