import {motion} from 'framer-motion';
import React, {
	useEffect,
	useState,
}               from 'react';

// Based on https://codesandbox.io/s/5ohg1?file=/src/Example.js:189-428

export function TextLoop(
	{
		items,
		duration = 3000,
	},
) {
	const [index, setIndex] = useState(0);

	const variants = {
		enter:  direction => {
			return {
				y:       -20,
				opacity: 0,
			};
		},
		center: {
			zIndex:  1,
			y:       0,
			opacity: 1,
		},
		exit:   direction => {
			return {
				zIndex:  0,
				opacity: 0,
			};
		},
	};

	useEffect(() => {
		const interval = setInterval(() => {
			setIndex((currentIndex) => {
				return (currentIndex + 1) % items.length;
			});
		}, duration);

		return () => {
			clearInterval(interval);
		};
	}, [
		items,
		duration,
	]);

	return (
		<motion.span
			style={{position: 'absolute'}}
			variants={variants}
			key={index}
			initial="enter"
			animate="center"
			exit="exit"
			transition={{
				y:       {
					type:      'spring',
					stiffness: 1000,
					damping:   100,
				},
				opacity: {
					duration: 0.25,
				},
			}}
		>
			{items[index]}
		</motion.span>
	);
}
