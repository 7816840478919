import {Grid}      from '@/domains/marketing/components/Backgrounds/Grid';
import {Container} from '@/domains/marketing/components/Container';

import {Button} from '@/domains/shared/components/Button';

export function CallToAction() {
	return (
		<section
			id="get-started-today"
			className="relative overflow-hidden bg-rose-600 py-32"
		>
			<Grid className="absolute inset-0 max-w-none [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
			      columns={40}
			      opacity={0.5}
			/>

			<Container className="relative">
				<div className="mx-auto max-w-xl text-center">
					<h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">
						Start scaling your business.
					</h2>
					<p className="mt-4 text-lg tracking-tight text-white">
						It&apos;s time to make your onboarding process more efficient, so your business can do more for
						less.
					</p>
					<Button href="/contact-us"
					        color="white"
					        className="mt-10"
					>
						Get started today
					</Button>
				</div>
			</Container>
		</section>
	);
}
