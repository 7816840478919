import {Grid}             from '@/domains/marketing/components/Backgrounds/Grid';
import {Container}        from '@/domains/marketing/components/Container';
import onboardingProgress from '@/domains/marketing/images/screenshots/onboarding-progress.png';
import trainingAttempts   from '@/domains/marketing/images/screenshots/training-attempts.png';
import vevoChecks         from '@/domains/marketing/images/screenshots/vevo-checks.png';
import {Tab}              from '@headlessui/react';
import clsx               from 'clsx';
import Image              from 'next/image';
import {
	useEffect,
	useState,
}                         from 'react';

const features = [
	{
		title: 'Onboarding',
		description:
		       'Send candidates a self-service invite and we will guide them through your custom onboarding workflow. Track candidates as they progress through their application, with drop-off risk reporting to prevent lost candidates.',
		// image: 'https://placehold.co/2174x1464/fff1f2/9f1239.png?text=Onboarding',
		image: onboardingProgress,
	},
	{
		title: 'Compliance',
		description:
		       'Hire the right people and stay compliant by automatically conducting VEVO checks and police checks. Candidates can also provide proof of licences, education, and prior employment.',
		// image: 'https://placehold.co/2174x1464/fff1f2/9f1239.png?text=Compliance',
		image: vevoChecks,
	},
	{
		title: 'Training',
		description:
		       'Upload your e-learning modules and candidates can independently complete these as part of their onboarding.',
		// image: 'https://placehold.co/2174x1464/fff1f2/9f1239.png?text=Training',
		image: trainingAttempts,
	},
	// {
	// 	title: 'Payees',
	// 	description:
	// 	       'Capture accurate bank, tax, and super information automatically, so that new hires get paid correctly from day one.',
	// 	image: 'https://placehold.co/2174x1464/fff1f2/9f1239.png?text=Payees',
	// },
];

export function PrimaryFeatures() {
	let [tabOrientation, setTabOrientation] = useState('horizontal');

	useEffect(() => {
		let lgMediaQuery = window.matchMedia('(min-width: 1024px)');

		function onMediaQueryChange({matches}) {
			setTabOrientation(matches ? 'vertical' : 'horizontal');
		}

		onMediaQueryChange(lgMediaQuery);
		lgMediaQuery.addEventListener('change', onMediaQueryChange);

		return () => {
			lgMediaQuery.removeEventListener('change', onMediaQueryChange);
		};
	}, []);

	return (
		<section
			id="features"
			aria-label="Features for hiring at scale"
			className="relative overflow-hidden bg-rose-500 pb-28 pt-20 sm:py-32"
		>
			<Grid className="absolute inset-0 max-w-none [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
			      rows={30}
			      columns={30}
			      opacity={0.5}
			/>

			<Container className="relative">
				<div className="max-w-2xl md:mx-auto md:text-center xl:max-w-none">
					<h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl md:text-5xl">
						Everything you need to hire at scale.
					</h2>
					<p className="mt-6 text-lg tracking-tight text-rose-100">
						RecruitKit makes it easy to onboard new hires with the click of a button.
					</p>
				</div>
				<Tab.Group
					as="div"
					className="mt-16 grid grid-cols-1 items-center gap-y-2 pt-10 sm:gap-y-6 md:mt-20 lg:grid-cols-12 lg:pt-0"
					vertical={tabOrientation === 'vertical'}
				>
					{({selectedIndex}) => (
						<>
              <div className="-mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5">
                <Tab.List className="relative z-10 flex gap-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1 lg:whitespace-normal">
                  {features.map((feature, featureIndex) => (
	                  <div
		                  key={feature.title}
		                  className={clsx(
			                  'group relative rounded-full px-4 py-1 lg:rounded-l-xl lg:rounded-r-none lg:p-6',
			                  selectedIndex === featureIndex
				                  ? 'bg-white lg:bg-white/20 lg:ring-1 lg:ring-inset lg:ring-white/20'
				                  : 'hover:bg-white/20 lg:hover:bg-white/5',
		                  )}
	                  >
		                  <h3>
			                  <Tab
				                  className={clsx(
					                  'font-display text-lg [&:not(:focus-visible)]:focus:outline-none',
					                  selectedIndex === featureIndex
						                  ? 'text-rose-600 lg:text-white'
						                  : 'text-rose-100 hover:text-white lg:text-white',
				                  )}
			                  >
				                  <span className="absolute inset-0 rounded-full lg:rounded-l-xl lg:rounded-r-none" />
				                  {feature.title}
			                  </Tab>
		                  </h3>
		                  <p
			                  className={clsx(
				                  'mt-2 hidden text-sm lg:block',
				                  selectedIndex === featureIndex
					                  ? 'text-white'
					                  : 'text-rose-100 group-hover:text-white',
			                  )}
		                  >
			                  {feature.description}
		                  </p>
	                  </div>
                  ))}
                </Tab.List>
              </div>
              <Tab.Panels className="lg:col-span-7">
                {features.map((feature) => (
	                <Tab.Panel key={feature.title}
	                           unmount={false}
	                >
		                <div className="relative sm:px-6 lg:hidden">
			                <div className="absolute -inset-x-4 bottom-[-4.25rem] top-[-6.5rem] bg-white/10 ring-1 ring-inset ring-white/10 sm:inset-x-0 sm:rounded-t-xl" />
			                <p className="relative mx-auto max-w-2xl text-base text-white sm:text-center">
				                {feature.description}
			                </p>
		                </div>
		                <div className="mt-10 w-[45rem] overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-rose-900/20 sm:w-auto lg:mt-0 lg:w-[67.8125rem]">
			                <Image
				                className="w-full"
				                src={feature.image}
				                width={2174}
				                height={1464}
				                alt=""
				                priority
				                sizes="(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem"
			                />
		                </div>
	                </Tab.Panel>
                ))}
              </Tab.Panels>
            </>
					)}
				</Tab.Group>
			</Container>
		</section>
	);
}
