import {CallToAction}      from '@/domains/marketing/components/CallToAction';
import {Hero}              from '@/domains/marketing/components/Hero';
import {PrimaryFeatures}   from '@/domains/marketing/components/PrimaryFeatures';
import {SecondaryFeatures} from '@/domains/marketing/components/SecondaryFeatures';
import PrimaryLayout       from '@/domains/marketing/layouts/PrimaryLayout';

export default function Home() {
	return (<PrimaryLayout pageTitle="RecruitKit - Automated onboarding software for recruitment agencies"
	                       description="Cut your hiring costs up to 75% by automating your recruiting and onboarding processes for new hires."
	>
		<Hero tiledClassName="-translate-y-28" />
		<PrimaryFeatures />
		<SecondaryFeatures />
		<CallToAction />
	</PrimaryLayout>);
}
